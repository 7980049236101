
<template>
    <div class="Client font">
        <!-- BEGIN: Content-->
        <div class="app-content content">
            <div class="content-overlay"></div>
            <div class="header-navbar-shadow"></div>

            <div class="content-wrapper container-xxl p-0 font">
                <banner title="CLIENT KYC" :breadcrumb="[
                
                    { label: 'Client Kyc' },
                ]">
                </banner>

                <div class="row font" style="margin-top: -45px">
                    <div class="col-xl-5 col-md-5 col-sm-5">
                        <div class="card radius"
                            style="height: 100%; box-shadow: 0px 0px 10px gray;margin-right:5px;margin-bottom:0px;height:100%;">
                            <div class="card-body pt-2">
                                <div class="row">
                                    <div class="col">
                                        <h4 class="d-xl-none d-md-none d-block text-center">
                                            <b style="color: #00364f;font-weight: bolder;">Client Kyc</b>
                                        </h4>
                                        <h4 class="d-none d-xl-block d-md-block text-start">
                                            <b style="color: #00364f;font-weight: bolder;">Client Kyc</b>
                                        </h4>
                                    </div>
                                </div>

                                <div class="row">
                                    <!-- img responsive mobile  -->
                                    <div
                                        class="col-sm-12 col-12 d-block d-md-none d-xl-none d-lg-none text-center mb-1">
                                        <img style="width:40%;border-radius:15px;" :src="profileImg" alt="">
                                        <br>
                                        <span style="color: #00364f;">
                                               <p v-if="client.loginStatus == 'true'"
                                                                class="text-white btn btn-sm"
                                                                style="background-color:green;width:70px; box-shadow: 2px 2px 5px gray; border-radius: 50px; font-size:10px;padding-top:5px;padding-bottom:5px;padding-left:7px;padding-right:7px;margin-top:5px;margin-bottom:5px;">
                                                                <b> Active</b>
                                                            </p>
                                                            <p v-else class="text-white btn btn-sm"
                                                                style="background-color:red;width:70px; box-shadow: 2px 2px 5px gray; border-radius: 50px; font-size:10px;padding-top:5px;padding-bottom:5px;padding-left:7px;padding-right:7px;margin-top:5px;margin-bottom:5px;">
                                                                <b> InActive</b>
                                                            </p>
                                        </span>
                                    </div>
                                    <h5 class="text-start">
                                        <b style="color: #f21300;font-weight: bolder;">Personal Details </b>
                                    </h5>

                                    <div class="col-xl-9 col-md-9 col-lg-9 col-sm-12">
                                        <div class="text">
                                            <img style="width: 8px; margin-right: 5px" src="/assets/image/record.png"
                                                alt="" />
                                            <b>Code : </b>
                                            <span v-if="client">{{ client.code }}</span>
                                        </div>

                                        <div class="text">
                                            <img style="width: 8px; margin-right: 5px" src="/assets/image/record.png"
                                                alt="" />
                                            <b>Name : </b>
                                            <span v-if="client">{{ client.name }}</span>
                                        </div>

                                        <div class="text">
                                            <img style="width: 8px; margin-right: 5px" src="/assets/image/record.png"
                                                alt="" />
                                            <b>DOB : </b>
                                            <span v-if="client">{{ client.dob }}</span>
                                        </div>



                                        <div class="text">
                                            <img style="width: 8px; margin-right: 5px" src="/assets/image/record.png"
                                                alt="" />
                                            <b>Phone : </b>
                                            <span v-if="client">{{ client.phone }}</span>
                                        </div>



                                        <div class="text">
                                            <img style="width: 8px; margin-right: 5px" src="/assets/image/record.png"
                                                alt="" />
                                            <b>E-mail : </b>
                                            <span v-if="client">{{ client.email }}</span>
                                        </div>
                                        <div class="text">
                                            <img style="width: 8px; margin-right: 5px" src="/assets/image/record.png"
                                                alt="" />
                                            <b>Address : </b>
                                            <span v-if="client">{{ client.address }}</span>
                                        </div>
                                        <div class="text">
                                            <img style="width: 8px; margin-right: 5px" src="/assets/image/record.png"
                                                alt="" />
                                            <b>State : </b>
                                            <span v-if="state">{{ state.name }}</span>
                                        </div>

                                    </div>
                                    <!-- img responsive Pc  -->
                                    <div
                                        class="col-xl-3 col-md-3  col-lg-3 d-none d-xl-block d-md-block d-lg-block text-center">
                                        <img style="width:100%;border-radius:15px;margin-top:-20px;" :src="profileImg"
                                            alt="">
                                        <span style="color: #00364f;width:40%;">
                                            <!-- v-if="retailer.status == 'true'" -->
                                                <p v-if="client.loginStatus == 'true'"
                                                                class="text-white btn btn-sm"
                                                                style="background-color:green;width:70px; box-shadow: 2px 2px 5px gray; border-radius: 50px; font-size:10px;padding-top:5px;padding-bottom:5px;padding-left:7px;padding-right:7px;margin-top:5px;margin-bottom:5px;">
                                                                <b> Active</b>
                                                            </p>
                                                            <p v-else class="text-white btn btn-sm"
                                                                style="background-color:red;width:70px; box-shadow: 2px 2px 5px gray; border-radius: 50px; font-size:10px;padding-top:5px;padding-bottom:5px;padding-left:7px;padding-right:7px;margin-top:5px;margin-bottom:5px;">
                                                                <b> InActive</b>
                                                            </p>
                                        </span>
                                    </div>
                                </div>

                                <div class="row">
                                    <h5 class="text-start mt-2">
                                        <b style="color: #f21300;font-weight: bolder;">Retailer Kyc & Account
                                            Details</b>
                                    </h5>
                                    <div class="col-xl-12 col-lg-12 col-md-12 col-12">
                                        <div class="text">
                                            <img style="width: 8px; margin-right: 5px" src="/assets/image/record.png"
                                                alt="" />
                                            <b>Account Number : </b>
                                            <span>{{ clientKyc.accountNo }}</span>
                                        </div>
                                        <div class="text">
                                            <img style="width: 8px; margin-right: 5px" src="/assets/image/record.png"
                                                alt="" />
                                            <b>Account Type : </b>
                                            <span v-if="clientKyc">{{ clientKyc.accountType }}</span>
                                        </div>


                                        <div class="text">
                                            <img style="width: 8px; margin-right: 5px" src="/assets/image/record.png"
                                                alt="" />
                                            <b>Aadhar Number : </b>
                                            <span v-if="clientKyc">{{ clientKyc.aadharNo }}</span>
                                        </div>

                                        <div class="text">
                                            <img style="width: 8px; margin-right: 5px" src="/assets/image/record.png"
                                                alt="" />
                                            <b>Pan Number : </b>
                                            <span v-if="clientKyc">{{ clientKyc.panNo }}</span>
                                        </div>


                                    </div>
                                </div>




                                <div class="row mt-1">
                                    <div class="col-12 text-end">
                                        <button type="button" class="btn text-white btn-sm" data-bs-toggle="modal"
                                            data-bs-target="#doccuments"
                                            style="background-color:#f21300;margin-right:5px;"><b>Kyc Doccuments
                                                &nbsp;
                                                <i class="fa fa-hand-pointer-o"></i>
                                            </b></button>

                                    </div>
                                </div>
                                <div class="row mt-1" style="background-color:#E3E3E3;border-radius:15px;">
                                    <div class="col-12 p-1">
                                        <div class="text">
                                            <img style="width: 8px; margin-right: 5px" src="/assets/image/record.png"
                                                alt="" />
                                            <b>IP Address :</b>
                                            <span v-if="client">{{ client.ipAddress }}</span>
                                        </div>
                                        <div class="text">
                                            <img style="width: 8px; margin-right: 5px" src="/assets/image/record.png"
                                                alt="" />
                                            <b>Last Login :</b>
                                            <span v-if="client">{{ client.last_login }}</span>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>

                    </div>

                    <div class="col-xl-7 col-md-7 col-sm-7">
                        <div class="d-xl-none d-md-none d-block pt-1"></div>
                        <div class="card radius" style="box-shadow: 0px 0px 10px gray;margin-left:3px;height:100%;">
                            <div class="card-body">
                                <!-- @submit.prevent="getOtherInformation" -->
                                 <form >
                                        <div class="form-group">
                                            <h3 class="text"><b>User Kyc</b></h3>
                                            
                                        </div>
                                     


                                        <div class="row">
                                            <div class="col-md-6 gap-1 form-group"
                                                style="padding-top:6px;padding-right:6px;">
<!-- v-model="clientkyc.panNo" -->  <label>Pan Number</label>
                                                <input type="text" class="form-control btn-sm" 
                                                    id="" placeholder="Pan Number">

                                            </div>
                                            <div class="col-md-6  gap-1 form-group" style=";padding-top:6px;">
 <!-- v-model="clientkyc.aadharNo" -->  <label>Aadhar Number</label>
                                                <input type="number" class="form-control btn-sm"
                                                 placeholder="Aadhar Number">

                                            </div>
                                        </div>


                                        <div class="row ">
                                            <div class="col-md-6 gap-1 form-group"
                                                style="padding-top:6px;padding-right:6px;">
<!-- v-model="client.dob" -->                       <label>Acount Number</label>
                                                <input type="text" class="form-control btn-sm" 
                                                  placeholder="Acount number">

                                            </div>
                                            <div class="col-md-6 gap-1 form-group " style=";padding-top:6px;">
<!-- v-model="client.gender" -->  <label>Acount Type</label>
                                                <select class="form-select btn-sm" 
                                                    aria-label=".btn-sm example">

                                                    <option value="saving">Saving</option>
                                                    <option value="current">Current</option>

                                                </select>

                                            </div>
                                        </div>


                                        <div class="row">
                                            <div class="col-md-12 form-group gap-1 mt-1">
                                                 <button type="submit" class="btn text-white btn-sm" style="background-color:#00364f;"><b>Confirm</b></button>

 </div>

                                        </div>

                                    </form>
                             
                               <div class="row mt-2">
                                        <div class="col-md-12 mt-1">
                                            <h4 class="text-danger font"> <b> Upload Documents</b> </h4>

                                            <div class="row mx-0">
                                                <div class="col-md-6 col-sm-6 col-xs-6 text border border-dark pt-2 pb-2 text-center"
                                                    style="border-radius: 15px 0px 0px 0px;">
                                                    <b class="">
                                                        Profile Image</b>
                                                </div>
                                                <div class="col-md-6 col-sm-6 col-xs-6 text border border-dark p-1"
                                                    style="border-radius: 0px 15px 0px 0px;">
                                                    <div class="btn-group btn-group-sm" role="group"
                                                        aria-label="Basic radio toggle button group">
                                                        <button type="button" class="btn btn-outline-warning btn-sm">
                                                            <font-awesome-icon icon="edit" />
                                                        </button>
                                                        <div class="image-upload border border-danger"
                                                            style="padding:5px 10px 5px 10px">
                                                            <label for="file-input">
                                                                <img style="width:15px;height:15px;"
                                                                    src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQmfaX8geGxoa0HOjTuCneI_GGlPm0SSOfZ6g&usqp=CAU" />
                                                            </label>
                                                            <input class='input-file' id="file-input"
                                                                @change="uploadPhoto" type="file" accept="image/*"
                                                                required />

                                                        </div>
                                                        <button type="button" class="btn btn-outline-danger  btn-sm">
                                                            <font-awesome-icon icon="trash" />
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row  mx-0">
                                                <div
                                                    class="col-md-6 col-sm-6 col-xs-6 text border border-dark pt-2 pb-2 text-center">
                                                    <b>
                                                        PAN Card</b>
                                                </div>
                                                <div class="col-md-6 col-sm-6 col-xs-6 text border border-dark p-1 ">
                                                    <div class="btn-group btn-group-sm" role="group"
                                                        aria-label="Basic radio toggle button group">
                                                        <button type="button" class="btn btn-outline-warning btn-sm">
                                                            <font-awesome-icon icon="edit" />
                                                        </button>

                                                        <div class="image-upload border border-danger"
                                                            style="padding:5px 10px 5px 10px">
                                                            <label for="file-input">
                                                                <img style="width:15px;height:15px;"
                                                                    src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQmfaX8geGxoa0HOjTuCneI_GGlPm0SSOfZ6g&usqp=CAU" />
                                                            </label>
                                                            <input class='input-file' id="file-input"
                                                                @change="uploadPanPhoto" type="file" accept="image/*"
                                                                required />

                                                        </div>




                                                        <button type="button" class="btn btn-outline-danger  btn-sm">
                                                            <font-awesome-icon icon="trash" />
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row  mx-0">
                                                <div
                                                    class="col-md-6  col-sm-6 col-xs-6 text border border-dark pt-2 pb-2 text-center">
                                                    <b>
                                                        Aadhaar Front</b>
                                                </div>
                                                <div class="col-md-6 col-sm-6 col-xs-6 text border border-dark p-1">
                                                    <div class="btn-group btn-group-sm" role="group"
                                                        aria-label="Basic radio toggle button group">
                                                        <button type="button" class="btn btn-outline-warning btn-sm">
                                                            <font-awesome-icon icon="edit" />
                                                        </button>

                                                        <div class="image-upload border border-danger"
                                                            style="padding:5px 10px 5px 10px">
                                                            <label for="file-input">
                                                                <img style="width:15px;height:15px;"
                                                                    src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQmfaX8geGxoa0HOjTuCneI_GGlPm0SSOfZ6g&usqp=CAU" />
                                                            </label>
                                                            <input class='input-file' id="file-input"
                                                                @change="uploadAdharFrontPhoto" type="file"
                                                                accept="image/*" required />

                                                        </div>
                                                        <button type="button" class="btn btn-outline-danger  btn-sm">
                                                            <font-awesome-icon icon="trash" />
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row  mx-0">
                                                <div
                                                    class="col-md-6 col-sm-6 col-xs-6 text border border-dark pt-2 pb-2 text-center">
                                                    <b>
                                                        Aadhaar Back</b>
                                                </div>
                                                <div class="col-md-6 col-sm-6 col-xs-6 text border border-dark p-1">
                                                    <div class="btn-group btn-group-sm" role="group"
                                                        aria-label="Basic radio toggle button group">

                                                        <button type="button" class="btn btn-outline-warning btn-sm">
                                                            <font-awesome-icon icon="edit" />
                                                        </button>

                                                        <div class="image-upload border border-danger"
                                                            style="padding:5px 10px 5px 10px">
                                                            <label for="file-input">
                                                                <img style="width:15px;height:15px;"
                                                                    src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQmfaX8geGxoa0HOjTuCneI_GGlPm0SSOfZ6g&usqp=CAU" />
                                                            </label>
                                                            <input class='input-file' id="file-input"
                                                                @change="uploadAdharBackPhoto" type="file"
                                                                accept="image/*" required />

                                                        </div>

                                                        <button type="button" class="btn btn-outline-danger  btn-sm">
                                                            <font-awesome-icon icon="trash" />
                                                        </button>
                                                    </div>
                                                </div>

                                            </div>
                                            <div class="row  mx-0">
                                                <div class="col-md-6 col-sm-6 col-xs-6 text border border-dark pt-2 pb-2 text-center"
                                                    style="border-radius: 0px 0px 0px 15px;">
                                                    <b>
                                                        Passbook</b>
                                                </div>
                                                <div class="col-md-6 col-sm-6 col-xs-6 text border border-dark p-1"
                                                    style="border-radius: 0px 0px 15px 0px;">
                                                    <div class="btn-group btn-group-sm" role="group"
                                                        aria-label="Basic radio toggle button group">
                                                        <button type="button" class="btn btn-outline-warning btn-sm">
                                                            <font-awesome-icon icon="edit" />
                                                        </button>


                                                        <div class="image-upload border border-danger"
                                                            style="padding:5px 10px 5px 10px">
                                                            <label for="file-input">
                                                                <img style="width:15px;height:15px;"
                                                                    src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQmfaX8geGxoa0HOjTuCneI_GGlPm0SSOfZ6g&usqp=CAU" />
                                                            </label>
                                                            <input class='input-file' id="file-input"
                                                                @change="uploadPassbookPhoto" type="file"
                                                                accept="image/*" required />
                                                            <!-- <input id="file-input" type="file"/> -->
                                                        </div>



                                                        <button type="button"
                                                            class="btn btn-outline-danger btns btn-sm">
                                                            <font-awesome-icon icon="trash" />
                                                        </button>


                                                    </div>

                                                        <div class="btn btn-succes btn-sm text-white"> 28.4</div>

                                                </div>
                                            </div>

                                        </div>
                                    </div>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
        </div>
        <!-- END: Content-->

        <div class="sidenav-overlay"></div>
        <div class="drag-target"></div>
    </div>
    
    <!-- Modal kyc doccuments show  -->
    <div class="modal fade" id="doccuments" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title text" id="exampleModalLabel"><b>Kyc Doccuments</b></h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="container-fluid table-scroll">

                        <table class="table table-hover table-sm">
                            <tr class="table-wrapper-scroll-y text-white rounded-circle"
                                style="background-color:#00364f; height:40px;">
                                <th>+</th>
                                <th>Image</th>
                                <th>Name</th>
                                <th>Action</th>

                            </tr>

                            <tbody>

                                <tr style="color:#00364f;">
                                    <td><img style="width: 8px; margin-right: 5px" src="/assets/image/record.png"
                                            alt="" /></td>
                                    <td><img style="width: 100px; margin-right: 5px" :src="adharFrontImg" alt="" /></td>

                                    <td>
                                        <p>Aadhar Front</p>
                                    </td>
                                    <td>
                                        <div class="btn-group btn-group-sm" role="group">
                                            <a :href="adharFrontImg"
                                                style="padding:5px 4.5px 5px 4.5px;background-color:#00364f;"
                                                class="btn btns text-white btn-sm" download>
                                                <i class="fa fa-download" aria-hidden="true"></i>
                                            </a>
                                            <button type="button" class="btn btns btn-sm text-white"
                                                style="background-color:#f21300;padding:5px 4.5px 5px 4.5px;">
                                                <i class="fa fa-expand"></i>
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                                <tr style="color:#00364f;">
                                    <td><img style="width: 8px; margin-right: 5px" src="/assets/image/record.png"
                                            alt="" /></td>
                                    <td><img style="width: 100px; margin-right: 5px" :src="adharBackImg" alt="" /></td>

                                    <td>Aadhar Back</td>
                                    <td>
                                        <div class="btn-group btn-group-sm" role="group">
                                            <a :href="adharBackImg"
                                                style="padding:5px 4.5px 5px 4.5px;background-color:#00364f;"
                                                class="btn btns text-white btn-sm" download="true">
                                                <i class="fa fa-download" aria-hidden="true"></i>
                                            </a>
                                            <button type="button" class="btn btns btn-sm text-white"
                                                style="background-color:#f21300;padding:5px 4.5px 5px 4.5px;">
                                                <i class="fa fa-expand"></i>
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                                <tr style="color:#00364f;">
                                    <td><img style="width: 8px; margin-right: 5px" src="/assets/image/record.png"
                                            alt="" /></td>
                                    <td><img style="width: 100px; margin-right: 5px" :src="panImg" alt="" /></td>

                                    <td>
                                        <p>Pan Card</p>
                                    </td>
                                    <td>
                                        <div class="btn-group btn-group-sm" role="group">
                                            <a :href="panImg"
                                                style="padding:5px 4.5px 5px 4.5px;background-color:#00364f;"
                                                class="btn btns text-white btn-sm" download>
                                                <i class="fa fa-download" aria-hidden="true"></i>
                                            </a>
                                            <button type="button" class="btn btns btn-sm text-white"
                                                style="background-color:#f21300;padding:5px 4.5px 5px 4.5px;">
                                                <i class="fa fa-expand"></i>
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                                <tr style="color:#00364f;">
                                    <td><img style="width: 8px; margin-right: 5px" src="/assets/image/record.png"
                                            alt="" /></td>
                                    <td><img style="width: 100px; margin-right: 5px" :src="passBookImg" alt="" /></td>

                                    <td>
                                        <p> PassBook</p>
                                    </td>
                                    <td>
                                        <div class="btn-group btn-group-sm" role="group">
                                            <a href="https://img.freepik.com/free-photo/male-female-business-people-working-tablet-office_1303-22826.jpg?size=626&ext=jpg&uid=R72207921&ga=GA1.2.809908789.1655402312"
                                                style="padding:5px 4.5px 5px 4.5px;background-color:#00364f;"
                                                class="btn btns text-white btn-sm" download>
                                                <i class="fa fa-download" aria-hidden="true"></i>
                                            </a>
                                            <button type="button" class="btn btns btn-sm text-white"
                                                style="background-color:#f21300;padding:5px 4.5px 5px 4.5px;">
                                                <i class="fa fa-expand"></i>

                                            </button>

                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <!-- </form> -->
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn text-white btn-sm" data-bs-dismiss="modal"
                        style="background-color:#f21300;">Close</button>
                    <!-- <button type="button" class="btn btn-primary">Save changes</button> -->
                </div>
            </div>
        </div>
    </div>

    <!-- wallet retailer -->
</template>

//
<script>
import Banner from "../../../components/retailer/comman/Banner.vue";
// import Form from "vform";
// import jQuery from "jquery";
// let $ = jQuery
export default {
    name: "Client Profile",

    components: {
        Banner,
    },
    data() {
        return {
            clientKyc: {},
            client: {},
            profileImg: this.$store.state.placeholderImg,
            state: {},
            adharFrontImg: this.$store.state.placeholderImg,
            adharBackImg: this.$store.state.placeholderImg,
            panImg: this.$store.state.placeholderImg,
            passBookImg: this.$store.state.placeholderImg,
            passBookImgUrl: '',
           
           };
    },
    methods: {
      

     
        loadClients() {
            this.$axios
                .get(`retailer/client/${this.$route.params.id}`, { headers: { Authorization: 'Bearer ' + localStorage.accessToken } })
                .then(res => {
                    // console.log(res.data)
                    // console.log("User Data")
                    this.client = res.data.data
                    console.log(this.client)
                    if (this.client.photo != null) {
                        this.profileImg = this.$store.state.imgUrl + '/client/' + this.client.id + '/thumbs/' + res.data.data.photo
                    } else {
                        this.profileImg = this.$store.state.placeholderImg
                    }


                })
        },
        loadClientKycs() {
            this.$axios
                .get(`retailer/clientkyc?client_id=${this.$route.params.id}`, { headers: { Authorization: 'Bearer ' + localStorage.accessToken } })
                .then(res => {
                   
                    this.clientKyc = res.data.data.data[0]
                    console.log(this.clientKyc)
                    if (this.clientKyc.aadharBackPhoto != null) {

                        this.adharBackImg = this.$store.state.imgUrl + '/client/' + this.$route.params.id + '/thumbs/' +this.clientKyc.aadharBackPhoto
                    } else {
                        this.adharBackImg = this.$store.state.placeholderImg
                    }

                    if (this.clientKyc.aadharFrontPhoto != null) {
                        this.adharFrontImg = this.$store.state.imgUrl + '/client/' + this.$route.params.id + '/thumbs/' +this.clientKyc.aadharFrontPhoto
                    } else {
                        this.adharFrontImg = this.$store.state.placeholderImg
                    }

                    if (this.clientKyc.panPhoto != null) {
                        this.panImg = this.$store.state.imgUrl + '/client/' + this.$route.params.id + '/thumbs/' + this.clientKyc.panPhoto
                    } else {
                        this.panImg = this.$store.state.placeholderImg
                    }

                    if (this.clientKyc.passbookPhoto != null) {
                        this.passBookImg = this.$store.state.imgUrl + '/client/' + this.$route.params.id + '/thumbs/' +this.clientKyc.passbookPhoto

                    } else {
                        this.passBookImg = this.$store.state.placeholderImg
                    }


                })
        },


    },
    created() {
        if (
            this.$route.meta.redirectIfLoggedIn == "true" &&
            localStorage.getItem("accessToken") == null
        ) {
            this.$router.push({ name: "Login" });
        } else {
            // this.loadWallet() 
            //   this.loadRetailers()
            //   this.loadStates()
            //   this.loadBeneficiaryAccount()
            this.loadClients()
            this.loadClientKycs()
        }
    },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap");

.custom-card {
    width: 95%;
    background-color: white;
    border-radius: 20px;
    box-shadow: 2px 2px 8px gray;
    line-height: 10%;
    margin-left: 13px;
}

.h4-heading {
    color: red;
    font-size: 1.2vw;
}

.font-heading {
    font-family: "Poppins", sans-serif;
    font-size: 3rem;
    color: rgb(216, 209, 209);
}

.text {
    font-family: "Poppins", sans-serif;
    color: #00364f;
}

.font {
    font-family: "Poppins", sans-serif;
}

.btns {
    background-color: #00364f;
    font-size: 12px;
    padding: 8px 8px 8px 8px;
}

.radius {
    border-radius: 23px;
}

.form-check-input:checked {
    background-color: #ffffff;
    border-color: #e70c0c;
    border-width: 3px;
}

.tr-head {
    font-size: 11pt;
    margin-left: 25px;
    border-radius: 26px;
    background-color: #CDCDCD;
}

.table-scroll {
    overflow-y: auto;
}
</style>
